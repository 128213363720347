import { bigcommerceAppClientId } from '../_common/constants.js';

const baseFitmentFields = ['Year', 'Make', 'Model', 'Submodel'];
const extraFitmentFields = [];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields, 'Fitment', 'Universal', 'Vehicle'];

const categorySelectionPageUrl = '/parts/';

const isVehicleLangingPages = window.location.pathname.startsWith('/vehicles/');

if (isVehicleLangingPages) {
  // show it via the 'CategoryFacet' widget
  ignoreFields.push('category');
}

function getLocalPreselection(pageType, defaults) {
  if (defaults.termFromBreadcrumbs.startsWith('Vehicles')) {
    // show category facet on vehicle landings
    return [
      {
        field: 'category',
        term: defaults.termFromBreadcrumbs,
        treeLevel: -1,
      },
    ];
  }

  return defaults.getter(pageType);
}

globalThis.Convermax.redirectToParts = (e, baseSelectVehicle) => {
  if (window.location.pathname === '/') {
    baseSelectVehicle();
  } else {
    e.stopPropagation();
    if (window.location.pathname !== categorySelectionPageUrl) {
      window.location.assign(categorySelectionPageUrl);
    }
  }
};

globalThis.Convermax.handleProductCompareClick = () => {
  const productsIdsToCompare = [...window.document.querySelectorAll('input[name="products[]"]:checked')].map(
    (product) => product.dataset.compareId,
  );
  const compareBtn = window.document.body.querySelector('.cm_card-compare_link');
  const compareCount = window.document.body.querySelector('.cm_card-compare_count');
  if (!compareBtn) {
    return;
  }

  compareCount.textContent = productsIdsToCompare.length;
  compareBtn.classList.toggle('show', productsIdsToCompare.length > 1);
  compareBtn.href = `/compare/${productsIdsToCompare.join('/')}/`;
};

function InitFunc() {
  window.Convermax.fitmentSearch = { title: getFitmentSearchTitle() };
}

const updateCallback = () => {
  if (window.affirm) {
    window.affirm.ui.ready(function () {
      window.affirm.ui.refresh();
    });
  }
};

function getFitmentSearchTitle() {
  switch (window.location.pathname) {
    case categorySelectionPageUrl:
      return 'Categories';
    case '/search/':
      return 'Parts';
  }
  return (
    window.document.querySelector('.themevale_breadcrumb h1.page-heading')?.textContent ||
    window.document.querySelector('.themevale_breadcrumbCategory h1.page-heading')?.textContent ||
    'Parts'
  );
}

const customerGroupId = getGroupId();
async function getGroupId() {
  if (!globalThis.customer_id) {
    return '';
  }
  const groupId = await new Promise((resolve) => {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState === 4) {
        if (xmlHttp.status === 200) {
          const jwt = xmlHttp.responseText;
          const [, jsonPart] = jwt.split('.');
          const { customer } = JSON.parse(atob(jsonPart));
          resolve(customer.group_id);
        } else if (xmlHttp.status === 404) {
          resolve(null);
        } else {
          resolve(null);
        }
      }
    };
    xmlHttp.open('GET', `/customer/current.jwt?app_client_id=${bigcommerceAppClientId}`, true);
    xmlHttp.send();
  });
  return groupId || '';
}

export default {
  platform: 'bigcommerce',
  getLocalPreselection,
  InitFunc,
  defaultView: '3',
  SearchRequestDefaults: {
    pageSize: 36,
    extra: {
      customerGroupId,
    },
  },
  autocomplete: {
    requestDefaults: { extra: { customerGroupId } },
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [{ pathname: categorySelectionPageUrl, field: 'category' }],
    categorySelectionPageRedirect: true,
    getFitmentSearchTitle: () => window.Convermax.fitmentSearch.title,
    expectResponseRedirect: true,
  },
  product: {
    noImageSrc:
      'https://cdn11.bigcommerce.com/s-40kcmfrwg5/stencil/15490f50-ff8e-0139-6ed2-32ede3865cdc/e/7dada670-0779-013a-9da3-228f13e021ff/img/ProductDefault.gif',
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      visibleIf: () => window.location.pathname === '/search/',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPageWithSidebar',
      type: 'SearchPage',
      location: 'body.page-type-category #grid-list-layout>.container',
      template: 'CategoryPageWithSidebar',
      visibleIf: () =>
        !window.document.querySelector(
          'body.page-type-category #grid-list-layout>.container>aside#faceted-search-container',
        ),
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'body.page-type-category #product-listing-container',
      template: 'MainContent',
      visibleIf: () =>
        window.document.querySelector(
          'body.page-type-category #grid-list-layout>.container>aside#faceted-search-container',
        ),
    },
    {
      name: 'CategoryFacetPanel',
      type: 'FacetPanel',
      location: {
        insertBefore: 'body.page-type-category #featured-products',
        class: 'cm_facet-panel_container',
      },
      template: 'FacetPanelContainer',
      ignoreFields,
    },
    {
      name: 'CategoryFacetTiles',
      type: 'FacetPanel',
      visibleIf: () => isVehicleLangingPages,
      disableCollapse: true,
      fields: ['category'],
      showAllAlways: true,
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchBox',
      location: '.headerMiddle-item .themevale_search-custom',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.themevale_header-Mobile .item--searchMobile',
        class: 'items item--searchMobile',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'Garage',
      location: { insertBefore: '.item-wislish', class: 'cm_garage_container' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm_header-ymm',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 800,
      initCollapsed: true,
    },
    {
      name: 'SearchVehicleLabel',
      type: 'VehicleWidget',
      location: {
        replace: '.themevale_breadcrumb .page-heading, .themevale_breadcrumbCategory .page-heading',
      },
      template: 'fitmentSearch/searchVehicleWidgetContainer',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select',
      sortEntries: {
        'relevance': 'Best Match',
        'price': 'Price: Low to High',
        'price:desc': 'Price: High to Low',
        'name': 'Name',
        'date_created:desc': 'Newest',
      },
      facetToggleConfig: {
        toggledFacet: { field: 'Universal', term: 'True' },
        visibleIfFields: baseFitmentFields,
      },
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm_parts',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertAfter: '.productView-product' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: '#tabWarranty-content',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
