
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function searchVehicleWidgetContainerRT () {
    return _createElement('div', { 'className': 'page-title-wrapper' }, this.template === 'locked' ? _createElement('div', {
        'className': 'cmTemplate_locked',
        'key': '35'
    }, !!this.vehicleString ? _createElement('h1', {
        'className': 'page-heading',
        'key': '116'
    }, _createElement('span', {
        'className': 'base',
        'data-ui-id': 'page-title-wrapper'
    }, this.vehicleString, ' ', this.fitmentSearchTitle)) : null) : null, this.template !== 'locked' ? _createElement('h1', {
        'className': 'page-heading',
        'key': '303'
    }, _createElement('span', {
        'className': 'base',
        'data-ui-id': 'page-title-wrapper'
    }, this.fitmentSearchTitle)) : null);
}
        export const componentNames = []