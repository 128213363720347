//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-816:_6261,_1172,_7510,_8488,_192,_2464,_9473,_9168;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-816')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-816', "_6261,_1172,_7510,_8488,_192,_2464,_9473,_9168");
        }
      }catch (ex) {
        console.error(ex);
      }